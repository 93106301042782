import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MenuItems from './MenuItems';
import PropTypes from 'prop-types';

const FooterMenu = ({ locale = 'nl', name }) => (
  <StaticQuery
    query={graphql`
      query {
        allBuroBorkMenu {
          edges {
            node {
              id
              field
              name
              locale
            }
          }
        }
      }
    `}
    render={data => {
      let menu = data.allBuroBorkMenu.edges.find(
        edge => edge.node.locale === locale && edge.node.name == name
      );
      if (menu) {
        return <MenuItems items={menu.node.field} />;
      } else {
        return '';
      }
    }}
  />
);

FooterMenu.propTypes = {
  locale: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default FooterMenu;
