import React from 'react';
// import { StaticQuery, graphql } from 'gatsby';
// import { FormattedMessage } from 'react-intl';
// import MenuItems from 'components/menuItems';
import PropTypes from 'prop-types';
import Logo from 'images/new_logo.svg';
// import Contact from './contact';
// import LocaleMenu from './localeMenu.js';
// import FooterBlock from './FooterBlock';
import moment from 'moment';
import FooterMenu from './footerMenu';

const Footer = () => (
  <React.Fragment>
    <footer className="bukazu-footer">
      <hr className="divider-dark" />
      <div className="container">
        <div className="d-md-flex row bukazu-footer-menu my-4">
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <p className="bukazu-p-medium bukazu-p-medium--bold heading mb-3">
              Producten
            </p>
            <FooterMenu name="Footer-product" />
          </div>
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <p className="bukazu-p-medium bukazu-p-medium--bold heading mb-3">
              Doelgroepen
            </p>
            <FooterMenu name="Footer-targets" />
          </div>
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <p className="bukazu-p-medium bukazu-p-medium--bold heading mb-3">
              Extra informatie
            </p>
            <FooterMenu name="Footer-help" />
          </div>
          <div className="col-6 col-sm-4 col-md-3 col-xl-2">
            <p className="bukazu-p-medium bukazu-p-medium--bold heading mb-3">
              Bedrijf
            </p>
            <FooterMenu name="Footer-company" />
          </div>
        </div>
      </div>
      <hr className="divider-dark" />
      <div className="container">
        <div className="row px-3 py-4">
          <div className="col-6 col-sm-4 col-md-3 col-xl-3 footer-logo">
            <Logo />
          </div>
        </div>
      </div>
      <hr className="divider-dark" />
      <div className="container">
        <div className="row bukazu-footer-copy px-3 py-3 justify-content-between justify-content-md-start">
          <div className="pl-md-0 col-md-6 d-flex justify-content-center justify-content-md-start">
            <p className="bukazu-p-medium mb-3 text-center text-md-left">
              Copyright {moment().format('YYYY')} Bukazu
            </p>
          </div>
        </div>
      </div>
    </footer>
  </React.Fragment>
);

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Footer;
