import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { IntlProvider, addLocaleData } from 'react-intl'
// import enData from 'intl/locale-data/en'
// import nlData from 'intl/locale-data/nl'
// import deData from 'intl/locale-data/de'
import Head from 'components/head'
import Header from 'components/header'
// import GlobalStyle from 'global.css.js';
import Footer from 'components/footer'
// import Zendesk from 'react-zendesk'
// const ZENDESK_KEY = '29599b68-c99f-445e-8852-c9b52219cd2e'

import en from 'locales/en.json'
import nl from 'locales/nl.json'
import de from 'locales/de.json'

import 'style/main.scss'

const messages = { en, nl, de }

// addLocaleData([...enData, ...nlData, ...deData])
const setting = {
  color: {
    theme: '#000',
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
  contactForm: {
    fields: [{ id: 'description', prefill: { '*': 'Uw bericht' } }],
  },
}

const Layout = ({ data, locale, children, className }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <div>
      {/* <GlobalStyle /> */}
      <Head locale={locale} />
      <Header title={data.title} logo={data.logo} locale={locale} />
      <main className={className}>{children}</main>
      <Footer locale={locale} />
      {/* <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={() => console.log('is loaded')}
      /> */}
    </div>
  </IntlProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  locale: PropTypes.string,
  className: PropTypes.string,
}

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        buroBorkSite {
          id
          field
        }
      }
    `}
    render={data => {
      return <Layout data={JSON.parse(data.buroBorkSite.field)} {...props} />
    }}
  />
)

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWithQuery
